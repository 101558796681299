@import './base.style.scss';

*::-webkit-scrollbar {
	width: 5px;
}

*::-webkit-scrollbar-track {
	background: $spanner-white-color;
}

*::-webkit-scrollbar-thumb {
	background-color: $notify-border;
	border-radius: 20px;
	border: 3px solid $notify-border;
}

body {
	color: $blue-color;
	font-family: Arial, Helvetica, sans-serif;
}

a,
a:active,
a:hover {
	text-decoration: none;
	color: $full-black;
}

.rs_btm {
	padding-top: 20px;
	padding-bottom: 10px;
}

.rs_btm1 {
	border-bottom: 1px solid $full-black;
	margin-bottom: 4px;
}

::-webkit-input-placeholder {
	/* Edge */
	font-weight: bold;
}

:-ms-input-placeholder {
	/* Internet Explorer */
	font-weight: bold;
}

::placeholder {
	font-weight: bold;
}

.no-info {
	p {
		text-align: center;
		color: $dark-red;
		font-weight: bold;
	}

}

.table-loading-outer:hover {
	background-color: transparent !important;
}

