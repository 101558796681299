@import '../../base.style.scss';

.bat-basic {
    color: $light-dark-blue-color !important;
	border: 2px solid $light-dark-blue-color !important;
	background-color: $original-white !important;
    /* border-radius: 3px; */
}

.bat-basic.btn-sm {
    min-width: 4rem;
}

.bat-basic:hover, 
.bat-basic:active,
.bat-basic:focus,
.bat-basic:disabled {
	color: $light-dark-blue-color !important;
	border: 2px solid $light-dark-blue-color !important;
	background-color: $original-white !important;
}

.bat-basic:disabled {
    cursor: crosshair;
}

.bat-dark-blue {
    background-color: $light-dark-blue-color !important;
	border: 2px solid $light-dark-blue-color !important;
	color: $original-white !important;
    /* border-radius: 3px; */
}

.bat-dark-blue.btn-sm {
    min-width: 4rem;
}

.bat-dark-blue:hover, 
.bat-dark-blue:active,
.bat-dark-blue:focus,
.bat-dark-blue:disabled {
	background-color: $light-dark-blue-color !important;
	border: 2px solid $light-dark-blue-color !important;
	color: $original-white !important;
}

.bat-dark-blue:disabled {
    cursor: crosshair;
}

.btn-xs {
    --bs-btn-padding-y: 0.15rem !important;
    --bs-btn-padding-x: 0.25rem !important;
    --bs-btn-font-size: 0.85rem !important;
    --bs-btn-border-radius: 0.15rem !important;
}

.bat-basic.btn-xs {
    min-width: 3rem;
}