@import '../../base.style.scss';

.page-not-found-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    justify-content: flex-start;

    header {
        position: relative;
            padding: 10px 33px;
            background-image: linear-gradient(to right,
                $rainbow-dark-blue 85%,
                $rainbow-light-blue 88%,
                $rainbow-orange 94%,
                $rainbow-dark-yellow 96%,
                $rainbow-green 97%,
                $rainbow-light-green 98%,
                $rainbow-purple 99%,
                $rainbow-pink 100%);
        background-size: 100% 3px;
        background-position: bottom;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo-section {
            .logo-image {
                width: 120px;
            }
        }
    }
    .page-not-found-outlet {
        min-height: 83vh;
        display: flex;
        align-items: center;
        justify-content: center;
        .page-not-found-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            gap: 1.5rem;
            h1 {
                margin: 0;
                padding: 0;
            }
        }
    }
    .page-not-found-footer {
        background-image: linear-gradient(to right,
                $rainbow-dark-blue 85%,
                $rainbow-light-blue 88%,
                $rainbow-orange 94%,
                $rainbow-dark-yellow 96%,
                $rainbow-green 97%,
                $rainbow-light-green 98%,
                $rainbow-purple 99%,
                $rainbow-pink 100%);
        background-size: 100% 3px;
        background-position: top;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: right;
        padding: 2px 5px;
    
        .ft-text {
            margin-top: 5px;
            padding: 0;
            font-size: 12px;
            font-weight: bold;
            color: $light-blue;
        }
    }
}