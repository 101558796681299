$blue-color: #243066;
$dark-blue-color: 3px solid #243066;
$skyblue-color: #34bfef;
$light-dark-blue-color: #21519f;
$dark-green: #40c443;
$dark-orange: #ffc20e;
$dark-red: #eb4747;
$ultra-light-red: #f3e6e8;
$dark-grey: #7ba5cf;
$full-black: black;
$light-blue: #004f9f;
$lighter-blue: #004f9f;
$light-green: #aec90c;
$light-orange: #edb27a;
$dark-pink: #b21fd9;
$light-pink: #d80ccb;

$light-yellow: #edbf16;
$light-grey: #d1d7e2;
$darkblue: #162c65;
$ultra-light-blue: #eaeef3;
$green-yellow: #50af47;
$dark-green-color: #5ab452;
$original-red: red;
$original-white: #fff;
$dom-fa-base-color: #dc6d02;
$packing-base-color: #c558e2;
$third-party-base-color: #edbf16;
$opportunity-base-color: #00b182;
$resource-cell-fill-color: #f6462a;
$none-showing-grey: #bfc8d8;
$border-grey: #898282;
$label-dark-grey: #707070;

// loader colors
$overlay-bg-dark-grey: rgba(0, 0, 0, 0.5);
$spanner-white-color: #fff;

// Rainbow border colors
$rainbow-dark-blue: #243066;
$rainbow-light-blue: #00b1eb;
$rainbow-orange: #ef7d00;
$rainbow-dark-yellow: #ffba00;
$rainbow-green: #50af47;
$rainbow-light-green: #afca0b;
$rainbow-purple: #5a328a;
$rainbow-pink: #e72582;
$crop-base-color: #7f9402;

// Notification colors
$notify-shadow: #00000029;
$notify-border: #ddd;
$notify-royal-blue: #102a63;
$notify-grey-blue: #e6edf5;
$notify-grey-dark-blue: #cfddec;

$grey-blue-color: #79a2cd;
$dom-fa-table-color: #f4f8fd;

// Inner header button colors
$inner-header-crop-plan-bg: #00b1eb;
$inner-header-dom-fa-bg: #ef7d00;
$inner-header-dom-fa-border: #e7994e;
$inner-header-packing-plan-bg: #ffba00;
$inner-header-third-party-bg: #50af47;
$inner-header-opportunity-stock-bg: #5a328a;

// Cell colors
$cell-fill-blue-bg: #5d94cc;
$cell-fill-red-bg: #f6462a;
$light-pink: #c97edc;

// Pagination
$pagination-border-gray: #dee2e6;
